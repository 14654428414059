.screenshot {
  width: 50%;
  margin-top: 50px;
}

.appLogo {
  width: 10%;
  margin-top: 20px;
}

.appBackground {
  background-color: #00a0e2;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerText {
  color: #fff;
  font-size: 2rem;
  font-family: "Canela-Light";
  margin-top: 40px;
}

.button {
  background-color: #fff;
  border: 0;
  border-radius: 200px;
  height: 10%;
  width: 40%;
  position: absolute;
  box-shadow: black 0px 0px 50px;
}

.buttonText {
  color: #00a0e2;
  font-size: 2rem;

  font-family: "AktivGrotesk-Bold";
}
