body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "AktivGrotesk-Regular";
  src: local("MyFont"), url(./fonts/AktivGrotesk-Regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-family: "AktivGrotesk-Bold";
  src: local("MyFont"), url(./fonts/AktivGrotesk-Bold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                                                      'embedded-opentype', and 'svg' */
  font-family: "Canela-Light";
  src: local("MyFont"), url(./fonts/Canela-Light.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                                                                                'embedded-opentype', and 'svg' */
}
